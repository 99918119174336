.hide {
    display: none
}

#widgets {
    padding: 100px 50px
}

.widgets {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-gap: 20px
}

@font-face {
    font-family: Poppins;
    src: url(https://iqm-assets.ams3.digitaloceanspaces.com/pdf/fonts/Poppins/Poppins-Regular.ttf);
    font-weight: 400
}

@font-face {
    font-family: Poppins;
    src: url(https://iqm-assets.ams3.digitaloceanspaces.com/pdf/fonts/Poppins/Poppins-Medium.ttf);
    font-weight: 500
}

@font-face {
    font-family: Poppins;
    src: url(https://iqm-assets.ams3.digitaloceanspaces.com/pdf/fonts/Poppins/Poppins-SemiBold.ttf);
    font-weight: 600
}

@font-face {
    font-family: Poppins;
    src: url(https://iqm-assets.ams3.digitaloceanspaces.com/pdf/fonts/Poppins/Poppins-ExtraBold.ttf);
    font-weight: 800
}

.widget {
    position: relative
}

.widget.widget--small {
    max-width: 360px
}

/* We dont use widget--large */
/* .widget.widget--large .widgetCard__row {
    padding: 30px
}

.widget.widget--large .widget__name {
    font-size: 4.4em
}

.widget.widget--large .widgetCard__h1 {
    font-size: 50px
}

.widget.widget--large .widgetCard__text {
    font-size: 20px
}

.widget.widget--large .widgetCard__text.widgetCard__text--large {
    font-size: 26px
} */

.widget.widget--float {
    width: 360px;
    position: fixed;
    z-index: 99999999999
}

.widget.widget--float.widget--top-right {
    right: 20px;
    top: 20px
}

.widget.widget--float.widget--top-left {
    left: 20px;
    top: 20px
}

.widget.widget--float.widget--bottom-right {
    right: 20px;
    bottom: 20px
}

.widget.widget--float.widget--bottom-left {
    left: 20px;
    bottom: 20px
}

.widget .widget__btn {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 30px;
    height: 30px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    cursor: pointer
}

.widget .widget__btn:hover svg circle {
    stroke: #f2b83b
}

.widget .widget__btn:hover svg path {
    stroke: #f2b83b
}

.widget .widget__btn svg {
    width: 23px;
    height: 23px
}

.widget .widget__btn svg circle {
    stroke: #28ae6f;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -o-transition: all .3s ease;
    -ms-transition: all .3s ease;
    transition: all .3s ease
}

.widget .widget__btn svg path {
    stroke: #28ae6f;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -o-transition: all .3s ease;
    -ms-transition: all .3s ease;
    transition: all .3s ease
}

.widget .widget__item {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    text-decoration: none;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -o-transition: all .3s ease;
    -ms-transition: all .3s ease;
    transition: all .3s ease
}

.widget .widget__item .widgetCard {
    z-index: 2
}

.widget .widget__item {
    -webkit-transform: scale(1.015);
    -moz-transform: scale(1.015);
    -ms-transform: scale(1.015);
    -o-transform: scale(1.015);
    transform: scale(1.015)
}

.widget .widget__item .widget__mask {
    opacity: 1
}

.widget .widget__item .widgetCard__text--large {
    color: #f2b83b !important;
    text-shadow: 0 0 10px rgba(0, 0, 0, .2)
}

.widget .widget__mask {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    border-radius: 10px;
    -webkit-box-shadow: 0 0 30px rgba(0, 0, 0, .3);
    -moz-box-shadow: 0 0 30px rgba(0, 0, 0, .3);
    box-shadow: 0 0 30px rgba(0, 0, 0, .3);
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -o-transition: all .5s ease;
    -ms-transition: all .5s ease;
    transition: all .5s ease;
    opacity: 0;
    z-index: 0
}

.widgetCard {
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    border-radius: 10px;
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, .1);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, .1);
    box-shadow: 0 0 10px rgba(0, 0, 0, .1);
    width: 100%;
    overflow: hidden
}

.widgetCard .widget__name {
    position: absolute;
    left: 5%;
    top: 28%;
    font-family: Poppins;
    font-size: 1.4em;
    font-weight: 600;
    font-weight: 900;
    line-height: 1;
    color: #325dab;
    margin: 0;
    text-align: left;
    z-index: 1
}

.widgetCard .widgetCard__img {
    overflow: hidden;
    position: relative;
    width: 100%;
    padding-top: 60.08%;
    background-color: #fff;
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, .2);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, .2);
    box-shadow: 0 0 10px rgba(0, 0, 0, .2)
}

.widgetCard .widgetCard__img img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover
}

.widgetCard .widgetCard__discount {
    min-width: 68px;
    height: 68px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #f2b83b;
    font-family: Poppins;
    font-size: 24px;
    font-weight: 800;
    background-color: rgba(54, 84, 163, .8);
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0 7px;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    border-radius: 10px;
    z-index: 1
}

.widgetCard .widgetCard__h1 {
    font-family: Poppins;
    /* @Luka: Changed font-size from 24 to 20 */
    font-size: 18px;
    font-weight: 600;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    margin: 0;
    /* text-transform: uppercase; */
    text-align: center
}

.widgetCard .widgetCard__text {
    font-family: Poppins;
    font-size: 14px;
    line-height: 1.3;
    color: #333;
    font-weight: 400;
    text-align: center
}

.widgetCard .widgetCard__text span {
    font-weight: 600
}

.widgetCard .widgetCard__text.widgetCard__text--striked span {
    text-decoration: line-through
}

.widgetCard .widgetCard__text.widgetCard__text--large {
    margin-top: 20px;
    font-weight: 500;
    font-size: 18px;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -o-transition: all .3s ease;
    -ms-transition: all .3s ease;
    transition: all .3s ease
}

.widgetCard .widgetCard__row {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 15px
}

.widgetCard .widgetCard__row.widgetCard__row--col2 {
    justify-content: space-between
}

.widgetCard .widgetCard__row.widgetCard__row--white {
    background-color: #fff
}

.widgetCard .widgetCard__row.widgetCard__row--green {
    background-color: #28ae6f;
    position: absolute;
    bottom:0;
    left:0;
    right:0;

    /* @Luka: Added min and max height -> update this based on max-width of .widget */
    min-height:70px;
    max-height: 70px;

    /* Added padding */

    padding: 20px;
}

.widgetCard .widgetCard__row.widgetCard__row--green .widgetCard__text {
    color: #fff
}

/* md */
.widget.widget--md {
    max-width: 280px
}


.widget.widget--md .widgetCard .widgetCard__row.widgetCard__row--green {
    padding: 15px;
    
    /* @Luka: Added min and max height -> update this based on max-width of .widget */
    min-height: 50px;
    max-height: 50px;
}

.widget.widget--md .widgetCard .widgetCard__h1 {
    font-size: 14px
}

.widget.widget--md .widget__btn {
    top: 3px;
    right: 3px;
    width: 20px;
    height: 20px
}

.widget.widget--md .widget__btn svg {
    width: 20px;
    height: 20px
}

/* sm */
.widget.widget--sm {
    /* @Luka: I changed this from 180 to 220   */
    max-width: 220px
}


.widget.widget--sm .widgetCard .widgetCard__row.widgetCard__row--green {
    padding: 12px;
    
    /* @Luka: Added min and max height -> update this based on max-width of .widget */
    min-height:40px;
    max-height: 40px;
}

.widget.widget--sm .widgetCard .widgetCard__h1 {
    /* @Luka: I changed this from 13 to 12   */
    font-size: 11px
}

.widget.widget--sm .widget__btn {
    top: 3px;
    right: 3px;
    width: 15px;
    height: 15px
}

.widget.widget--sm .widget__btn svg {
    width: 15px;
    height: 15px
}